import { FileExcelOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Col, DatePicker, Input, Row, Space, Spin, Table } from 'antd'
import _omitBy from 'lodash/omitBy'
import _isEmpty from 'lodash/isEmpty'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import ExportData from '../components/ExportData'
import PhotographerList from '../components/PhotographerList'
import config from '../config'
import adminUserQuery from '../graphql/queries/adminUser.gql'

const PhotographerEvents = compose(
  inject('user'),
  inject('router'),
  observer
)(({ match, router, user }) => {
  const { currency } = config
  const { photographerId } = match.params
  const { location } = router
  const { from: _from, s: search, to: _to } = location.query
  const from = _from && moment(_from)
  const to = _to && moment(_to)
  const intl = useIntl()
  const variables = {
    id: photographerId,
    photographerId,
  }
  const { error, loading, data } = useQuery(adminUserQuery, { fetchPolicy: 'network-only', skip: !photographerId, variables })
  const { events } = data?.adminUser ?? {}
  const photographerLabel = photographerId ? events?.[0]?.photographers?.username || 'Unknown' : 'All Photographers'

  const handleExportData = (data) =>
    data?.events?.map((item) => {
      return {
        [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.title,
        [intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })]: moment(item.startDate).format('DD/MM/YYYY'),
        [intl.formatMessage({ id: 'app.photographerShare', defaultMessage: 'Photographer Share' })]: item.photographerShare ?? '-',
        [intl.formatMessage({ id: 'app.photographerShareAmount', defaultMessage: 'Photographer Share Amount' })]: item.photographerShareAmount ?? '-',
        [intl.formatMessage({ id: 'app.orderCount', defaultMessage: 'Order Count' })]: item.accessibleOrderCount ?? '-',
        [intl.formatMessage({ id: 'app.photoCount', defaultMessage: 'Photo Count' })]: item.photoCount ?? '-',
        [intl.formatMessage({ id: 'app.photoPurchaseCount', defaultMessage: 'Photo purchase count' })]: item.orderPhotoCount ?? '-',
      }
    })
  const handlePhotographerChange = (photographerId) => {
    if (!photographerId) {
      return router.push(`/events:balance:photographer`)
    }
    router.push(`/events:balance:photographer/${photographerId}`)
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy(
      {
        ...location.query,
        p: pagination.current && `${pagination.current}`,
        sort,
      },
      _isEmpty
    )
  }
  const handleSearch = (search) => {
    location.query = {
      ...location.query,
      s: search,
    }
  }
  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }
  const setStartDateRange = (from, to) => {
    location.query = _omitBy(
      {
        ...location.query,
        from,
        to,
      },
      _isEmpty
    )
    // TODO: close filter
  }

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }

  if (error) {
    return 'Error'
  }

  return (
    <>
      <Row gutter={12}>
        <Col sm={24} md={12} lg={8}>
          <PhotographerList onChange={handlePhotographerChange} value={photographerId} role={user.role} style={{ width: '100%' }} />
        </Col>
        <Col sm={24} md={12} lg={16}>
          <ExportData
            exportedData={handleExportData}
            fileName={`phorographer_events_export_${moment().format('YYYY_MM_DD')}.xlsx`}
            filter={variables}
            lazyQuery={adminUserQuery}
            sheetNames={[photographerLabel]}
            style={{ margin: '0.3em 1em 1em 0' }}
          >
            <FileExcelOutlined /> {intl.formatMessage({ id: 'app.export', defaultMessage: 'Export' })}
          </ExportData>
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={events}
        rowKey={(record) => record._id}
        scroll={{ x: true }}
        onChange={handleTableChange}
        // footer={() => <Row type="flex">
        //   <Col xs={3}>
        //     <span>รายรับทั้งหมด</span>
        //   </Col>
        //   <Col xs={21} style={{ textAlign: 'right' }}>
        //     {`${sum?.length > 0 ? sum.reduce((accumulator, currentValue) => accumulator + currentValue).toFixed(2) : 0} บาท`}
        //   </Col>
        // </Row>
        // }
      >
        {/* <Table.Column
          title='No.'
          dataIndex={['number']}
          key='number'
          render={(text, record, index) => index + 1}
        /> */}
        <Table.Column
          title={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
          dataIndex={['title']}
          key='title'
          fixed='left'
          filterDropdown={({ confirm }) => (
            <Input.Search
              defaultValue={search}
              onSearch={(value) => {
                handleSearch(value)
                confirm()
              }}
            />
          )}
        />
        {!photographerId && (
          <Table.Column
            title={intl.formatMessage({ id: 'app.photographer', defaultMessage: 'Photographer' })}
            dataIndex={['photographer', 'displayName']}
            key='photographer'
          />
        )}
        <Table.Column
          title={intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}
          dataIndex={['startDate']}
          key='startDate'
          sorter
          filterDropdown={({ confirm }) => (
            <Space.Compact block>
              <DatePicker.RangePicker
                value={[from, to]}
                onChange={(...args) => {
                  handleStartDateRangeChange(...args)
                  confirm()
                }}
              />
              <Button
                onClick={() => {
                  setStartDateRange(null, null)
                  confirm()
                }}
              >
                {intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}
              </Button>
            </Space.Compact>
          )}
          // filterDropdown={<Space.Compact block>
          //   <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
          //   <Button onClick={() => setStartDateRange(null, null)}>{intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}</Button>
          // </Space.Compact>}
          render={(startDate) => moment(startDate).format('ll')}
        />
        {/* <Table.Column
        title={<>วันสิ้นสุด<br />การคำนวณส่วนแบ่ง</>}
        dataIndex={['shareEndsAt']}
        key='shareEndsAt'
        sorter
        render={(shareEndsAt, { startDate }) => shareEndsAt && <>
          {moment(shareEndsAt).format('ll')} <br />
          ({moment(shareEndsAt).diff(startDate, 'days') + 1} วัน)
        </>}
      /> */}
        <Table.Column
          title={intl.formatMessage({ id: 'app.soldPhoto', defaultMessage: 'Photo Sold' })}
          dataIndex={['photographerShare']}
          key='photographerShare'
          render={(photographerShare, { photographerShareUnit }) =>
            photographerShare &&
            `${
              photographerShareUnit === 'fixed'
                ? intl.formatNumber(photographerShare, {
                    currency,
                    currencySign: 'standard',
                    maximumFractionDigits: 2,
                    style: 'currency',
                  })
                : `${photographerShare} %`
            }`
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.photographerRevenue', defaultMessage: 'Photographer Revenue' })}
          dataIndex={['photographerShareAmount']}
          key='photographerShareAmount'
          render={(photographerShareAmount) =>
            intl.formatNumber(photographerShareAmount, {
              currency,
              currencySign: 'standard',
              maximumFractionDigits: 2,
              style: 'currency',
            })
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.soldPhoto', defaultMessage: 'Photo Sold' })}
          dataIndex={['accessiblePhotoSoldCount']}
          key='accessiblePhotoSoldCount'
          // render={(_id) => (
          //   <EventOrderStats eventId={_id} sharedOnly>
          //     {({ loading, itemCount }) =>
          //       loading ? <Spin /> : <div>{itemCount}</div>
          //     }
          //   </EventOrderStats>
          // )}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.upload', defaultMessage: 'Upload' })}
          dataIndex={['photoCount']}
          key='photoCount'
          // render={(photographerShareAmount) => `${photographerShareAmount.toFixed(2)} บาท`}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.orderSold', defaultMessage: 'Order Sold' })}
          dataIndex={['orderPhotoCount']}
          key='orderPhotoCount'
          // render={(photographerShareAmount) => `${photographerShareAmount.toFixed(2)} บาท`}
        />
        {/* <Table.Column
        title={intl.formatMessage({ id: 'app.totalSold', defaultMessage: 'Total Sold' })}
        dataIndex={['totalShareAmountInSharePeriod']}
        key='totalShareAmountInSharePeriod'
        render={(totalShareAmountInSharePeriod) => `${intl.formatNumber(totalShareAmountInSharePeriod, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}`}
      />
      {['superadmin', 'admin', 'accounting'].includes(user.role) && <Table.Column
        title={<>เอกสาร<br/>สรุปยอด</>}
        dataIndex={['_id']}
        key='salesDocument'
        render={(eventId, { salespersonId }) =>
          <Button icon={<PrinterOutlined />} loading={printEventId === eventId} disabled={printEventId === eventId} onClick={() => handlePrint({ eventId, salespersonId })} />
        }
      />} */}
      </Table>
    </>
  )
})

export default PhotographerEvents
