import { FileExcelOutlined, PrinterOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Col, DatePicker, Input, Radio, Row, Space, Spin, Table, Upload } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _omitBy from 'lodash/omitBy'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import AdminUserList from '../components/AdminUserList'
import BrokerInfo from '../components/BrokerInfo'
import BrokerTransferButton from '../components/BrokerTransferButton'
import BrokerTransferRequestPrint from '../components/BrokerTransferRequestPrint'
import ExportData from '../components/ExportData'
import OrganizerTransferButton from '../components/OrganizerTransferButton'
import config from '../config'
import brokerEventsQuery from '../graphql/queries/brokerEvents.gql'

const BrokerEvents = compose(
  inject('user'),
  inject('router'),
  observer
)(({ match, router, user }) => {
  const { currency } = config
  const { brokerId } = match.params
  const { location } = router
  const { from: _from, paid, pb: printBrokerId, pe: printEventId, s: search, sort, to: _to } = location.query
  const from = _from && moment(_from)
  const to = _to && moment(_to)
  const isPaid = ['true', 'false'].includes(paid) ? JSON.parse(paid) : undefined
  const intl = useIntl()
  const variables = {
    brokerId,
    brokerTransferExists: isPaid,
    limit: 1000,
    // role: 'broker',
    sort,
    titleSearch: search,
    ...(from &&
      to && {
        startDateRange: {
          gte: from?.toISOString(),
          lte: to?.toISOString(),
        },
      }),
  }
  const { error, loading, data } = useQuery(brokerEventsQuery, { variables })
  const { events } = data ?? {}
  const brokerLabel = brokerId ? events?.[0]?.broker?.formalName ?? 'Unknown' : 'All Brokers'
  const printData = events?.find((e) => e._id === printEventId)

  const handleExportData = (data) =>
    data?.events?.map((item) => {
      return {
        [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.title,
        [intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })]: moment(item.startDate).format('DD/MM/YYYY'),
        [intl.formatMessage({ id: 'app.shareDays', defaultMessage: 'Share days' })]:
          item.shareEndsAt && moment(item.shareEndsAt).diff(item.startDate, 'days') + 1,
        [intl.formatMessage({ id: 'app.paidDate', defaultMessage: 'Paid date' })]: item.brokerTransfer
          ? moment(item.brokerTransfer.completedAt).format('DD/MM/YYYY')
          : '-',
        [intl.formatMessage({ id: 'app.shareEndsAt', defaultMessage: 'Share Ends' })]:
          item.shareEndsAt && moment(item.shareEndsAt).format('DD/MM/YYYY'),
        [intl.formatMessage({ id: 'app.sharingType', defaultMessage: 'Sharing type' })]: `${item.brokerShare ?? ''}${
          !item.brokerShareUnit ? '-' : ''
        }${item.brokerShareUnit === 'percentage' ? '%' : ''} ${item.brokerShareUnit === 'fixed' ? 'fixed' : ''}`,
        [intl.formatMessage({ id: 'app.payer', defaultMessage: 'Payer' })]: item.brokerTransfer?.approver?.username ?? '-',
        [intl.formatMessage(
          { id: 'app.roleReceived', defaultMessage: '{role} received ({currency})' },
          { currency, role: intl.formatMessage({ id: 'app.broker', defaultMessage: 'Broker' }) }
        )]: item.brokerShareAmount ?? 0,
        [intl.formatMessage({ id: 'app.broker', defaultMessage: 'Broker' })]: item.broker?.name ?? '-',
        [intl.formatMessage({ id: 'app.paidAmount', defaultMessage: 'Paid amount' })]: item.brokerTransfer?.amount ?? '-',
        [intl.formatMessage({ id: 'app.status', defaultMessage: 'Status' })]: item.brokerTransfer ? 'paid' : 'unpaid',
      }
    })
  const handlePrint = ({ eventId, brokerId }) => {
    location.query = {
      ...location.query,
      pe: eventId,
      pb: brokerId,
    }
  }
  const handlePrintClose = () => {
    location.query = _omitBy(
      {
        ...location.query,
        pe: null,
        pb: null,
      },
      _isEmpty
    )
  }
  const handleBrokerChange = (brokerId) => {
    if (brokerId === 'all') {
      return router.push(`/events:balance:broker`)
    }
    router.push(`/events:balance:broker/${brokerId}`)
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy(
      {
        ...location.query,
        p: pagination.current && `${pagination.current}`,
        sort,
      },
      _isEmpty
    )
  }
  const handleSearch = (search) => {
    location.query = {
      ...location.query,
      s: search,
    }
  }
  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }
  const setStartDateRange = (from, to) => {
    location.query = _omitBy(
      {
        ...location.query,
        from,
        to,
      },
      _isEmpty
    )
    // TODO: close filter
  }
  const handlePaidStatus = (isPaid) => {
    location.query = _omitBy(
      {
        ...location.query,
        paid: isPaid,
      },
      _isUndefined
    )
  }
  const handleRequestRedirect = ({ brokerId, eventId }) => {
    return router.push(`/transfers/create?e=${eventId}&u=${brokerId}`)
  }

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error'
  }

  return (
    <>
      <Row gutter={12}>
        <Col sm={24} md={12} lg={8}>
          <AdminUserList onChange={handleBrokerChange} value={brokerId} role={'broker'} style={{ width: '100%' }} user={user} />
        </Col>
        <Col sm={24} md={12} lg={16}>
          <ExportData
            exportedData={handleExportData}
            fileName={`broker_events_export_${moment().format('YYYY_MM_DD')}.xlsx`}
            filter={variables}
            lazyQuery={brokerEventsQuery}
            sheetNames={[brokerLabel]}
            style={{ margin: '0.3em 1em 1em 0' }}
          >
            <FileExcelOutlined /> {intl.formatMessage({ id: 'app.export', defaultMessage: 'Export' })}
          </ExportData>
          <BrokerInfo brokerId={brokerId} />
        </Col>
      </Row>
      <Table
        loading={loading}
        dataSource={events}
        rowKey={(record) => record._id}
        scroll={{ x: true }}
        onChange={handleTableChange}
        // footer={() => <Row type="flex">
        //   <Col xs={3}>
        //     <span>รายรับทั้งหมด</span>
        //   </Col>
        //   <Col xs={21} style={{ textAlign: 'right' }}>
        //     {`${sum?.length > 0 ? sum.reduce((accumulator, currentValue) => accumulator + currentValue).toFixed(2) : 0} บาท`}
        //   </Col>
        // </Row>
        // }
      >
        {/* <Table.Column
          title='No.'
          dataIndex={['number']}
          key='number'
          render={(text, record, index) => index + 1}
        /> */}
        <Table.Column
          title={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
          dataIndex={['title']}
          key='title'
          fixed='left'
          filterDropdown={({ confirm }) => (
            <Input.Search
              defaultValue={search}
              onSearch={(value) => {
                handleSearch(value)
                confirm()
              }}
            />
          )}
        />
        {!brokerId && (
          <Table.Column title={intl.formatMessage({ id: 'app.broker', defaultMessage: 'Broker' })} dataIndex={['broker', 'name']} key='broker' />
        )}
        <Table.Column
          title={intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}
          dataIndex={['startDate']}
          key='startDate'
          sorter
          filterDropdown={({ confirm }) => (
            <Space.Compact block>
              <DatePicker.RangePicker
                value={[from, to]}
                onChange={(...args) => {
                  handleStartDateRangeChange(...args)
                  confirm()
                }}
              />
              <Button
                onClick={() => {
                  setStartDateRange(null, null)
                  confirm()
                }}
              >
                {intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}
              </Button>
            </Space.Compact>
          )}
          // filterDropdown={<Space.Compact block>
          //   <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
          //   <Button onClick={() => setStartDateRange(null, null)}>{intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}</Button>
          // </Space.Compact>}
          render={(startDate) => moment(startDate).format('ll')}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.shareEndsAt', defaultMessage: 'Share Ends' })}
          dataIndex={['shareEndsAt']}
          key='shareEndsAt'
          sorter
          render={(shareEndsAt, { startDate }) =>
            shareEndsAt && (
              <>
                {moment(shareEndsAt).format('ll')} <br />({moment(shareEndsAt).diff(startDate, 'days') + 1}{' '}
                {intl.formatMessage({ id: 'app.day', defaultMessage: 'Day' })})
              </>
            )
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.brokerShare', defaultMessage: 'Broker Share' })}
          dataIndex={['brokerShare']}
          key='brokerShare'
          render={(brokerShare, { brokerShareUnit }) => brokerShare && `${brokerShare} ${brokerShareUnit === 'fixed' ? 'บาท' : '%'}`}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.brokerRevenue', defaultMessage: 'Broker Revenue' })}
          dataIndex={['brokerShareAmount']}
          key='brokerShareAmount'
          render={(brokerShareAmount) => `${brokerShareAmount.toFixed(2)} บาท`}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.soldPhoto', defaultMessage: 'Photo Sold' })}
          dataIndex={['photoSoldCountInSharePeriod']}
          key='photoSoldCountInSharePeriod'
          // render={(_id) => (
          //   <EventOrderStats eventId={_id} sharedOnly>
          //     {({ loading, itemCount }) =>
          //       loading ? <Spin /> : <div>{itemCount}</div>
          //     }
          //   </EventOrderStats>
          // )}
        />
        <Table.Column
          title={`${intl.formatMessage({ id: 'app.photoSold', defaultMessage: 'Photo Sold' })} ${intl.formatMessage(
            { id: 'app.days', defaultMessage: '{day} days' },
            { day: 30 }
          )}`}
          dataIndex={['totalShareAmountInSharePeriod']}
          key='totalShareAmountInSharePeriod'
          render={(totalShareAmountInSharePeriod) =>
            intl.formatNumber(totalShareAmountInSharePeriod, {
              currency: currency,
              currencyDisplay: 'code',
              minimumFractionDigits: 2,
              style: 'currency',
            })
          }
        />
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && (
          <Table.Column
            title={intl.formatMessage({ id: 'app.salesDocument', defaultMessage: 'Sales Document' })}
            dataIndex={['_id']}
            key='salesDocument'
            render={(eventId, { brokerId }) => (
              <Button
                icon={<PrinterOutlined />}
                loading={printEventId === eventId}
                disabled={printEventId === eventId}
                onClick={() => handlePrint({ eventId, brokerId })}
              />
            )}
          />
        )}
        <Table.Column
          title={intl.formatMessage({ id: 'app.withdrawal', defaultMessage: 'Withdrawal' })}
          dataIndex={['transferRequests']}
          key='transferRequests'
          render={(transferRequests, { _id, brokerId }) => (
            <Button
              loading={loading}
              disabled={!!transferRequests}
              type='primary'
              onClick={(e) => e.stopPropagation() || handleRequestRedirect({ brokerId, eventId: _id })}
            >
              {intl.formatMessage({ id: 'app.submitRequest', defaultMessage: 'Submit Request' })}
            </Button>
          )}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.brokerTransfer', defaultMessage: 'Broker Transfer' })}
          dataIndex={['brokerTransfer']}
          key='brokerTransfer'
          width={200}
          filterDropdown={({ confirm }) => (
            <Radio.Group
              value={isPaid}
              onChange={(e) => {
                handlePaidStatus(e.target.value)
                confirm()
              }}
            >
              <Radio.Button value={true}>{intl.formatMessage({ id: 'app.paid', defaultMessage: 'Paid' })}</Radio.Button>
              <Radio.Button value={false}>{intl.formatMessage({ id: 'app.unpaid', defaultMessage: 'Unpaid' })}</Radio.Button>
              <Radio.Button value={undefined}>{intl.formatMessage({ id: 'app.all', defaultMessage: 'All' })}</Radio.Button>
            </Radio.Group>
          )}
          render={(brokerTransfer, item) => (
            <>
              {brokerTransfer?.amount && (
                <div>
                  {intl.formatNumber(brokerTransfer.amount, {
                    currency: currency,
                    currencySign: 'standard',
                    style: 'currency',
                  })}
                  {brokerTransfer?.completedAt && <>เมื่อ {moment(brokerTransfer?.completedAt).format('ll')}</>}
                </div>
              )}
              {brokerTransfer?.approver?.username && <div>โดย {brokerTransfer?.approver?.username}</div>}
              {['accounting'].includes(user.role) ? (
                <BrokerTransferButton event={item} />
              ) : (
                brokerTransfer?.slipUrl && (
                  <Upload
                    defaultFileList={[{ uid: brokerTransfer?.slipUrl, url: brokerTransfer?.slipUrl, name: 'ดาวน์โหลดสลิป' }]}
                    showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
                  />
                )
              )}
            </>
          )}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.withdrawalPartner', defaultMessage: 'Partner Withdrawal' })}
          dataIndex={['organizerTransfer']}
          key='organizerTransfer'
          width={200}
          filterDropdown={({ confirm }) => (
            <Radio.Group
              value={isPaid}
              onChange={(e) => {
                handlePaidStatus(e.target.value)
                confirm()
              }}
            >
              <Radio.Button value={true}>{intl.formatMessage({ id: 'app.paid', defaultMessage: 'Paid' })}</Radio.Button>
              <Radio.Button value={false}>{intl.formatMessage({ id: 'app.unpaid', defaultMessage: 'Unpaid' })}</Radio.Button>
              <Radio.Button value={undefined}>{intl.formatMessage({ id: 'app.all', defaultMessage: 'All' })}</Radio.Button>
            </Radio.Group>
          )}
          // render={(organizerBankSlip, { _id }) => <CloudImageUploader provider="gs" path="documents" value={organizerBankSlip} onChange={(value) => handleUpload(value, _id)} />}
          render={(organizerTransfer, item) => (
            <>
              {organizerTransfer?.amount && (
                <div>
                  {intl.formatNumber(organizerTransfer.amount, {
                    currency: currency,
                    currencySign: 'standard',
                    style: 'currency',
                  })}{' '}
                  {organizerTransfer?.completedAt && (
                    <>
                      {intl.formatMessage({ id: 'app.at', defaultMessage: 'At' })} {moment(organizerTransfer?.completedAt).format('ll')}
                    </>
                  )}
                </div>
              )}
              {organizerTransfer?.approver?.username && (
                <div>
                  {intl.formatMessage({ id: 'app.by', defaultMessage: 'By' })} {organizerTransfer?.approver?.username}
                </div>
              )}
              {['accounting'].includes(user.role) ? (
                <OrganizerTransferButton event={item} />
              ) : (
                organizerTransfer?.slipUrl && (
                  <Upload
                    defaultFileList={[
                      {
                        name: intl.formatMessage({ id: 'app.downloadSlip', defaultMessage: 'Download Slip' }),
                        uid: organizerTransfer?.slipUrl,
                        url: organizerTransfer?.slipUrl,
                      },
                    ]}
                    showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
                  />
                )
              )}
            </>
          )}
        />
      </Table>
      {printBrokerId && printData && <BrokerTransferRequestPrint brokerId={printBrokerId} data={printData} onClose={handlePrintClose} />}
      {/* <SalesForm {...salesDocumentData} /> */}
    </>
  )
})

export default BrokerEvents
