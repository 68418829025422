import React, { useEffect, useRef } from 'react'
import moment from 'moment-timezone'
import { useReactToPrint } from 'react-to-print'
import organizerQuery from '~/graphql/queries/organizer.gql'
import adminUserQuery from '~/graphql/queries/adminUser.gql'
import { useQuery } from '@apollo/client'
import { Spin, Row, Col } from 'antd'
import { useIntl } from 'react-intl'
import logo from '../assets/images/bigger-logo.png'
import config from '../config'

const OrganizerTransferRequestPrint = ({ organizerId, data: event, onClose }) => {
  const intl = useIntl()
  const { currency, platform, webBaseUrl } = config
  const { error: organizerError, loading: organizerLoading, data: organizerData } = useQuery(organizerQuery, {
    variables: {
      _id: organizerId
    },
    skip: !organizerId
  })
  const { error: adminUserError, loading: adminUserLoading, data: adminUserData } = useQuery(adminUserQuery, {
    variables: {
      id: organizerId
    },
    skip: !organizerId
  })

  const containerRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
    documentTitle: event.title,
    onAfterPrint: onClose
  })
  useEffect(() => {
    // console.log({ loading, organizerData })
    // handlePrint()
    if (!organizerLoading && organizerData?.organizer && !adminUserLoading && adminUserData?.adminUser) {
      handlePrint()
    }
  }, [organizerLoading, organizerData?.organizer, adminUserLoading, adminUserData?.adminUser]) // eslint-disable-line react-hooks/exhaustive-deps
  if (organizerLoading || adminUserLoading) {
    return <Spin />
  }
  if (!organizerData?.organizer || !adminUserData?.adminUser) {
    return
  }
  if (organizerError || adminUserError) {
    return 'Error'
  }
  const {
    formalName,
    identityDocumentUrl
  } = organizerData?.organizer
  const {
    profile,
    bankAccount
  } = adminUserData?.adminUser
  return <div style={{ display: 'none', margin: '1em' }}>
    <div ref={containerRef} className="print">
      <div style={{ textAlign: 'center' }} >
        <img src={logo} alt="Thai.run" style={{ height: '65px' }} />
        <p style={{ textAlign: 'right' }}>{`${intl.formatMessage({ id: 'app.no.', defaultMessage: 'No.' })}: ${moment().format('YYYY')}-${'.'.repeat(15)}`}</p>
        <p style={{ textAlign: 'right' }}>{`${intl.formatMessage({ id: 'app.date', defaultMessage: 'Date' })}: ${moment().format('D/M/YYYY')}`}</p>
        <p style={{ fontWeight: 700, marginTop: '20px' }}>{intl.formatMessage({ id: 'app.summaryReportOrganizer', defaultMessage: 'Summary Report of Photo Sales Revenue Share for Event Organizer' })}</p>
      </div>
      <div style={{ whiteSpace: 'pre-wrap' }}>
        <p>{`${intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}: ${event.title}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}: ${moment(event.startDate).format('ll')}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' })}: ${formalName}${' '.repeat(10)}${intl.formatMessage({ id: 'app.taxId', defaultMessage: 'Tax ID' })}: ${profile.nationalId}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.phone', defaultMessage: 'Phone' })}/${intl.formatMessage({ id: 'app.email', defaultMessage: 'Email' })}: ${profile.phone}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.bankAccountNumber', defaultMessage: 'Bank Account Number' })}: ${bankAccount?.number}${' '.repeat(10)}${intl.formatMessage({ id: 'app.bank', defaultMessage: 'Bank' })}: ${bankAccount?.brand && intl.formatMessage({ id: `app.banks.${bankAccount?.brand}` })}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.bankAccountName', defaultMessage: 'Bank Account Name' })}: ${bankAccount?.name}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.sharingType', defaultMessage: 'Sharing Type' })}: ${event.organizerShareUnit === 'fixed'
          ? `${intl.formatNumber(event.organizerShare, { currency, currencyDisplay: 'symbol', maximumFractionDigits: 2, style: 'currency' })}/${intl.formatMessage({ id: 'app.photo', defaultMessage: 'Photo' })}`
          : `${intl.formatMessage({ id: 'app.totalValuePercentageIn', defaultMessage: '{percentage} % of total value within {day} days' }, { percentage: event.organizerShare, day: moment(event.shareEndsAt).diff(event.startDate, 'day') + 1 })}`}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.details', defaultMessage: 'Details' })}:`}</p>
        <p>{`${intl.formatMessage(
            { id: 'app.photoSoldCount', defaultMessage: 'Photo sold: {count, plural, one {photo} other {photos}}' },
            { count: event.photoSoldCountInSharePeriod }
          )}${' '.repeat(10)}${intl.formatMessage(
            { id: 'app.totalOrderCount', defaultMessage: 'Total order: {count, plural, one {order} other {orders}}' },
            { count: event.orderCount }
          )}${' '.repeat(10)}${intl.formatMessage(
            { id: 'app.totalValueCount', defaultMessage: 'Total value: {count}' },
            { count: intl.formatNumber(event.totalShareAmountInSharePeriod, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }
          )}`}</p>
        <p>{`${intl.formatMessage({ id: 'app.sharedTotalCount', defaultMessage: 'Shared total: {count}' }, { count: intl.formatNumber(event.organizerShareAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) })}`}</p>
      </div>
      <br />
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <p>{intl.formatMessage({ id: 'app.reviewConfirm', defaultMessage: 'Review and Confirm' })}</p>
          <p style={{ fontWeight: 700 }}>{intl.formatMessage({ id: 'app.sign', defaultMessage: 'Sign' })}</p>
        </Col>
        <Col span={12} style={{ textAlign: 'left' }}>
          <p style={{ fontWeight: 700 }}>{`${platform === 'thai' ? 'บริษัท ไทยดอทรัน จำกัด' : `${webBaseUrl.replace('https://', '')}`}`}</p>
          <p style={{ fontWeight: 700 }}>{intl.formatMessage({ id: 'app.authorize1', defaultMessage: 'Authorize 1' })}</p>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <br />
          <p>{'.'.repeat(55)}</p>
          <p>{`(${'.'.repeat(70)})`}</p>
          <p>{`${intl.formatMessage({ id: 'app.dated', defaultMessage: 'Dated' })} ${'.'.repeat(55)}`}</p>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <br />
          <p>{'.'.repeat(55)}</p>
          <p>{`(${'.'.repeat(70)})`}</p>
          <p>{`${intl.formatMessage({ id: 'app.dated', defaultMessage: 'Dated' })} ${'.'.repeat(55)}`}</p>
        </Col>
        <Col span={12} offset={12} style={{ textAlign: 'center', marginTop: '40px' }}>
          <p style={{ fontWeight: 700, textAlign: 'left' }}>{intl.formatMessage({ id: 'app.authorize2', defaultMessage: 'Authorize 2' })}</p>
          <br />
          <p>{'.'.repeat(55)}</p>
          <p>{`(${'.'.repeat(70)})`}</p>
          <p>{`${intl.formatMessage({ id: 'app.dated', defaultMessage: 'Dated' })} ${'.'.repeat(55)}`}</p>
        </Col>
      </Row>
      {bankAccount?.passbookImageUrl && <div style={{ pageBreakBefore: 'always' }}>
        <img src={bankAccount?.passbookImageUrl} alt="" style={{ width: '100%' }} />
      </div>}
      {identityDocumentUrl && <div style={{ pageBreakBefore: 'always' }}>
        <img src={identityDocumentUrl} alt="" style={{ width: '100%' }} />
      </div>}
    </div>
  </div>
}

export default OrganizerTransferRequestPrint
