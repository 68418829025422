import { FileExcelOutlined, FilePdfOutlined, PrinterOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Button, Col, DatePicker, Input, Radio, Row, Space, Spin, Table, Upload } from 'antd'
import _isEmpty from 'lodash/isEmpty'
import _isUndefined from 'lodash/isUndefined'
import _omitBy from 'lodash/omitBy'
import { inject } from 'mobx-react'
import { observer } from 'mobx-react-lite'
import moment from 'moment'
import React from 'react'
import { useIntl } from 'react-intl'
import { compose } from 'recompose'
import AdminUserList from '../components/AdminUserList'
import ExportData from '../components/ExportData'
import OrganizerInfo from '../components/OrganizerInfo'
import OrganizerTransferButton from '../components/OrganizerTransferButton'
import OrganizerTransferRequestPrint from '../components/OrganizerTransferRequestPrint'
import config from '../config'
import organizerEventsQuery from '../graphql/queries/organizerEvents.gql'

const OrganizerEvents = compose(
  inject('user'),
  inject('router'),
  observer
)(({ match, router, user }) => {
  const { currency } = config
  const { organizerId } = match.params
  const { location } = router
  const { from: _from, paid, pe: printEventId, po: printOrganizerId, s: search, sort, to: _to } = location.query
  const from = _from && moment(_from)
  const to = _to && moment(_to)
  const isPaid = ['true', 'false'].includes(paid) ? JSON.parse(paid) : undefined
  const intl = useIntl()
  const variables = {
    limit: 1000,
    organizerId: match.params.organizerId,
    organizerTransferExists: isPaid,
    // role: 'organizer',
    sort,
    titleSearch: search,
    ...(from &&
      to && {
        startDateRange: {
          gte: from?.toISOString(),
          lte: to?.toISOString(),
        },
      }),
  }
  const { error, loading, data } = useQuery(organizerEventsQuery, { variables })
  const { events } = data ?? {}
  const organizerLabel = organizerId ? events?.[0]?.organizer?.formalName ?? 'Unknown' : 'All Organizers'
  const printData = events?.find((e) => e._id === printEventId)

  const handleExportData = (data) =>
    data?.events?.map((item) => {
      return {
        [intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })]: item.title,
        [intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })]: moment(item.startDate).format('DD/MM/YYYY'),
        [intl.formatMessage({ id: 'app.shareDays', defaultMessage: 'Share days' })]:
          item.shareEndsAt && moment(item.shareEndsAt).diff(item.startDate, 'days') + 1,
        [intl.formatMessage({ id: 'app.paidDate', defaultMessage: 'Paid date' })]: item.organizerTransfer
          ? moment(item.organizerTransfer.completedAt).format('DD/MM/YYYY')
          : '-',
        [intl.formatMessage({ id: 'app.shareEndsAt', defaultMessage: 'Share Ends' })]:
          item.shareEndsAt && moment(item.shareEndsAt).format('DD/MM/YYYY'),
        [intl.formatMessage({ id: 'app.sharingType', defaultMessage: 'Sharing type' })]: `${item.organizerShare ?? ''}${
          !item.organizerShareUnit ? '-' : ''
        }${item.organizerShareUnit === 'percentage' ? '%' : ''} ${item.organizerShareUnit === 'fixed' ? 'fixed' : ''}`,
        [intl.formatMessage({ id: 'app.payer', defaultMessage: 'Payer' })]: item.organizerTransfer?.approver?.username ?? '-',
        [intl.formatMessage(
          { id: 'app.roleReceived', defaultMessage: '{role} received ({currency})' },
          { currency, role: intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' }) }
        )]: item.organizerShareAmount ?? 0,
        [intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' })]: item.organizer?.name ?? '-',
        [intl.formatMessage({ id: 'app.paidAmount', defaultMessage: 'Paid amount' })]: item.organizerTransfer?.amount ?? '-',
        [intl.formatMessage({ id: 'app.status', defaultMessage: 'Status' })]: item.organizerTransfer ? 'paid' : 'unpaid',
      }
    })
  const handlePrint = ({ eventId, organizerId }) => {
    location.query = {
      ...location.query,
      pe: eventId,
      po: organizerId,
    }
  }
  const handlePrintClose = () => {
    location.query = _omitBy(
      {
        ...location.query,
        pe: null,
        po: null,
      },
      _isEmpty
    )
  }
  const handleOrganizerChange = (organizerId) => {
    if (organizerId === 'all') {
      return router.push(`/events:balance:organizer`)
    }
    router.push(`/events:balance:organizer/${organizerId}`)
  }
  const handleTableChange = (pagination, filters, sorter, extra) => {
    let sort
    if (sorter.order) {
      sort = `${sorter.columnKey}_${sorter.order.replace(/end$/, '')}`.toUpperCase()
    }
    location.query = _omitBy(
      {
        ...location.query,
        p: pagination.current && `${pagination.current}`,
        sort,
      },
      _isEmpty
    )
  }
  const handleSearch = (search) => {
    location.query = {
      ...location.query,
      s: search,
    }
  }
  const handleStartDateRangeChange = (_, [from, to]) => {
    setStartDateRange(from, to)
  }
  const setStartDateRange = (from, to) => {
    location.query = _omitBy(
      {
        ...location.query,
        from,
        to,
      },
      _isEmpty
    )
    // TODO: close filter
  }
  const handlePaidStatus = (isPaid) => {
    location.query = _omitBy(
      {
        ...location.query,
        paid: isPaid,
      },
      _isUndefined
    )
  }

  if (loading) {
    return <Spin style={{ display: 'block', lineHeight: '100vh' }} />
  }
  if (error) {
    return 'Error'
  }

  return (
    <>
      <Row gutter={12}>
        <Col sm={24} md={12} lg={8}>
          <AdminUserList onChange={handleOrganizerChange} value={organizerId} role={'organizer'} style={{ width: '100%' }} user={user} />
        </Col>
        <Col sm={24} md={12} lg={16}>
          <ExportData
            exportedData={handleExportData}
            fileName={`organizer_events_export_${moment().format('YYYY_MM_DD')}.xlsx`}
            filter={variables}
            lazyQuery={organizerEventsQuery}
            sheetNames={[organizerLabel]}
            style={{ margin: '0.3em 1em 1em 0' }}
          >
            <FileExcelOutlined /> {intl.formatMessage({ id: 'app.export', defaultMessage: 'Export' })}
          </ExportData>
          <OrganizerInfo organizerId={organizerId} />
        </Col>
      </Row>
      <Table
        // columns={columns}
        loading={loading}
        dataSource={events}
        rowKey={(record) => record._id}
        scroll={{ x: true }}
        onChange={handleTableChange}
        // footer={() => <Row type="flex">
        //   <Col xs={4}>
        //     <span>รายรับทั้งหมด</span>
        //   </Col>
        //   <Col xs={20} style={{ textAlign: 'right' }}>
        //     {events?.reduce((accumulator, { organizerShareAmount }) =>
        //       accumulator + (organizerShareAmount ?? 0), 0).toFixed(2)
        //     }
        //     {' '}
        //     บาท
        //   </Col>
        // </Row>
        // }
      >
        {/* <Table.Column
        title='No.'
        dataIndex={['number']}
        key='number'
        render={(text, record, index) => index + 1}
      /> */}
        {/* closeDropdown: boolean; */}

        <Table.Column
          title={intl.formatMessage({ id: 'app.eventTitle', defaultMessage: 'Event Title' })}
          dataIndex={['title']}
          key='title'
          fixed='left'
          filterDropdown={({ confirm }) => (
            <Input.Search
              defaultValue={search}
              onSearch={(value) => {
                handleSearch(value)
                confirm()
              }}
            />
          )}
        />
        {!organizerId && (
          <Table.Column
            title={intl.formatMessage({ id: 'app.organizer', defaultMessage: 'Organizer' })}
            dataIndex={['organizer', 'name']}
            key='organizer'
          />
        )}
        <Table.Column
          title={intl.formatMessage({ id: 'app.eventDate', defaultMessage: 'Event Date' })}
          dataIndex={['startDate']}
          key='startDate'
          sorter
          filterDropdown={({ confirm }) => (
            <Space.Compact block>
              <DatePicker.RangePicker
                value={[from, to]}
                onChange={(...args) => {
                  handleStartDateRangeChange(...args)
                  confirm()
                }}
              />
              <Button
                onClick={() => {
                  setStartDateRange(null, null)
                  confirm()
                }}
              >
                {intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}
              </Button>
            </Space.Compact>
          )}
          // filterDropdown={<Space.Compact block>
          //   <DatePicker.RangePicker value={[from, to]} onChange={handleStartDateRangeChange} />
          //   <Button onClick={() => setStartDateRange(null, null)}>{intl.formatMessage({ id: 'app.reset', defaultMessage: 'Reset' })}</Button>
          // </Space.Compact>}
          render={(startDate) => moment(startDate).format('ll')}
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.shareEndsAt', defaultMessage: 'Share Ends' })}
          dataIndex={['shareEndsAt']}
          key='shareEndsAt'
          sorter
          render={(shareEndsAt, { startDate }) =>
            shareEndsAt && (
              <>
                {moment(shareEndsAt).format('ll')} ({moment(shareEndsAt).diff(startDate, 'days') + 1}{' '}
                {intl.formatMessage({ id: 'app.day', defaultMessage: 'Day' })})
              </>
            )
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.sharingType', defaultMessage: 'Sharing Type' })}
          dataIndex={['organizerShare']}
          key='organizerShare'
          render={(organizerShare, { organizerShareUnit }) =>
            organizerShare &&
            `${organizerShare} ${organizerShareUnit === 'fixed' ? intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' }) : '%'}`
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.totalPartnerShare', defaultMessage: 'Total Partner Share' })}
          dataIndex={['organizerShareAmount']}
          key='organizerShareAmount'
          render={(organizerShareAmount) =>
            intl.formatNumber(organizerShareAmount, {
              currency,
              currencyDisplay: 'code',
              minimumFractionDigits: 2,
              style: 'currency',
            })
          }
        />
        <Table.Column
          title={intl.formatMessage({ id: 'app.photoSold', defaultMessage: 'Photo Sold' })}
          dataIndex={['photoSoldCountInSharePeriod']}
          key='photoSoldCountInSharePeriod'
          // render={(accessiblePhotoSoldCount) => `${accessiblePhotoSoldCount.toFixed(2)} ${intl.formatMessage({ id: 'app.baht', defaultMessage: 'Baht' })}`}
          // render={(_id) => (
          //   <EventOrderStats eventId={_id} sharedOnly>
          //     {({ loading, soldCount = 0 }) =>
          //       loading ? <Spin /> : <div>{soldCount}</div>
          //     }
          //   </EventOrderStats>
          // )}
        />
        <Table.Column
          title={`${intl.formatMessage({ id: 'app.photoSold', defaultMessage: 'Photo Sold' })} ${intl.formatMessage(
            { id: 'app.days', defaultMessage: '{day} days' },
            { day: 30 }
          )}`}
          dataIndex={['totalShareAmountInSharePeriod']}
          key='totalShareAmountInSharePeriod'
          render={(totalShareAmountInSharePeriod) =>
            intl.formatNumber(totalShareAmountInSharePeriod, {
              currency,
              currencyDisplay: 'code',
              minimumFractionDigits: 2,
              style: 'currency',
            })
          }
        />
        {['superadmin', 'admin', 'operation_admin', 'accounting'].includes(user.role) && (
          <Table.Column
            title={intl.formatMessage({ id: 'app.salesDocument', defaultMessage: 'Sales Document' })}
            dataIndex={['_id']}
            key='salesDocument'
            render={(eventId, { organizerId, contractDocumentUrl }) => (
              <div style={{ textAlign: 'center' }}>
                <Button
                  icon={<PrinterOutlined />}
                  loading={printEventId === eventId}
                  disabled={printEventId === eventId}
                  onClick={() => handlePrint({ eventId, organizerId })}
                />
                <br />
                {contractDocumentUrl && (
                  <Button icon={<FilePdfOutlined />}>
                    <a href={contractDocumentUrl}>{intl.formatMessage({ id: 'app.contract', defaultMessage: 'Contract' })}</a>
                  </Button>
                )}
              </div>
            )}
          />
        )}
        <Table.Column
          title={intl.formatMessage({ id: 'app.withdrawalPartner', defaultMessage: 'Partner Withdrawal' })}
          dataIndex={['organizerTransfer']}
          key='organizerTransfer'
          width={200}
          filterDropdown={({ confirm }) => (
            <Radio.Group
              value={isPaid}
              onChange={(e) => {
                handlePaidStatus(e.target.value)
                confirm()
              }}
            >
              <Radio.Button value={true}>{intl.formatMessage({ id: 'app.paid', defaultMessage: 'Paid' })}</Radio.Button>
              <Radio.Button value={false}>{intl.formatMessage({ id: 'app.unpaid', defaultMessage: 'Unpaid' })}</Radio.Button>
              <Radio.Button value={undefined}>{intl.formatMessage({ id: 'app.all', defaultMessage: 'All' })}</Radio.Button>
            </Radio.Group>
          )}
          // render={(organizerBankSlip, { _id }) => <CloudImageUploader provider="gs" path="documents" value={organizerBankSlip} onChange={(value) => handleUpload(value, _id)} />}
          render={(organizerTransfer, item) => (
            <>
              {organizerTransfer?.amount && (
                <div>
                  {intl.formatNumber(organizerTransfer.amount, {
                    currency,
                    currencySign: 'standard',
                    style: 'currency',
                  })}{' '}
                  {organizerTransfer?.completedAt && (
                    <>
                      {intl.formatMessage({ id: 'app.at', defaultMessage: 'At' })} {moment(organizerTransfer?.completedAt).format('ll')}
                    </>
                  )}
                </div>
              )}
              {organizerTransfer?.approver?.username && (
                <div>
                  {intl.formatMessage({ id: 'app.by', defaultMessage: 'By' })} {organizerTransfer?.approver?.username}
                </div>
              )}
              {['accounting'].includes(user.role) ? (
                <OrganizerTransferButton event={item} />
              ) : (
                organizerTransfer?.slipUrl && (
                  <Upload
                    defaultFileList={[
                      {
                        uid: organizerTransfer?.slipUrl,
                        url: organizerTransfer?.slipUrl,
                        name: intl.formatMessage({ id: 'app.downloadSlip', defaultMessage: 'Download Slip' }),
                      },
                    ]}
                    showUploadList={{ showRemoveIcon: false, showDownloadIcon: true }}
                  />
                )
              )}
            </>
          )}
        />
      </Table>
      {printOrganizerId && printData && <OrganizerTransferRequestPrint data={printData} onClose={handlePrintClose} organizerId={printOrganizerId} />}
      {/* <SalesForm {...salesDocumentData} /> */}
    </>
  )
})

export default OrganizerEvents
