import React, { useEffect, useRef } from 'react'
import moment from 'moment-timezone'
import { useReactToPrint } from 'react-to-print'
import brokerQuery from '~/graphql/queries/broker.gql'
import adminUserQuery from '~/graphql/queries/adminUser.gql'
import { useQuery } from '@apollo/client'
import { Spin, Row, Col } from 'antd'
import { FormattedMessage, useIntl } from 'react-intl'
import logo from '../assets/images/bigger-logo.png'
const BrokerTransferRequestPrint = ({ brokerId, data: event, onClose }) => {
  const intl = useIntl()
  const { error: brokerError, loading: brokerLoading, data: brokerData } = useQuery(brokerQuery, {
    variables: {
      _id: brokerId
    },
    skip: !brokerId
  })
  const { error: adminUserError, loading: adminUserLoading, data: adminUserData } = useQuery(adminUserQuery, {
    variables: {
      id: brokerId
    },
    skip: !brokerId
  })

  const containerRef = useRef()
  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
    documentTitle: event.title,
    onAfterPrint: onClose
  })
  useEffect(() => {
    // console.log({ loading, brokerData })
    // handlePrint()
    if (!brokerLoading && brokerData?.broker && !adminUserLoading && adminUserData?.adminUser) {
      handlePrint()
    }
  }, [brokerLoading, brokerData?.broker, adminUserLoading, adminUserData?.adminUser]) // eslint-disable-line react-hooks/exhaustive-deps
  if (brokerError || adminUserError) {
    return 'Error'
  }
  if (brokerLoading || adminUserLoading) {
    return <Spin />
  }
  if (!brokerData?.broker || !adminUserData?.adminUser) {
    return
  }
  const {
    formalName,
    identityDocumentUrl
  } = brokerData?.broker
  const {
    profile,
    bankAccount
  } = adminUserData?.adminUser
  return <div style={{ display: 'none' }}>
    <div ref={containerRef} className="print">
      <div style={{ textAlign: 'center' }} >
        <img src={logo} alt="Thai.run" style={{ height: '65px' }} />
        <p style={{ textAlign: 'right' }} >เลขที่: {moment().format('YYYY')}-………………</p>
        <p style={{ textAlign: 'right' }} >วันที่: {moment().format('D/M/YYYY')}</p>
        <p style={{ fontWeight: 700, marginTop: '20px' }}>ใบสำคัญสรุปยอดส่วนแบ่งจากการขายภาพให้โบรคเกอร์ (Broker)</p>
      </div>
      <div>
        <p>ชื่องานวิ่ง : {event.title} </p>
        <p>วันที่จัดงาน : {moment(event.startDate).format('ll')} </p>
        <p>ชื่อโบรคเกอร์ : {formalName} เลขที่ประจำตัวผู้เสียภาษี {profile.nationalId}</p>
        <p>เบอร์โทร/อีเมล์ : {profile.phone} </p>
        <p>เลขที่บัญชี : {bankAccount?.number} ธนาคาร : {bankAccount?.brand && <FormattedMessage id={`app.${bankAccount?.brand}`} />}</p>
        <p>ชื่อบัญชี : {bankAccount?.name} </p>
        <p>ประเภทส่วนแบ่ง : {event.brokerShare} {event.brokerShareUnit === 'fixed' ? 'บาท/ภาพ' : `% ของยอดขาย ${moment(event.shareEndsAt).diff(event.startDate, 'day') + 1} วัน`}</p>
        <p>รายละเอียด ดังนี้</p>
        <p>จำนวนภาพที่ขายได้ : {event.photoSoldCountInSharePeriod} ภาพ จำนวนออเดอร์ : {event.orderCount} ออเดอร์ คิดเป็นมูลค่า : {intl.formatNumber(event.totalShareAmountInSharePeriod, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
        <p>สรุปยอดส่วนแบ่ง : {intl.formatNumber(event.brokerShareAmount, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} บาท</p>
      </div>
      <br />
      <Row>
        <Col span={12} style={{ textAlign: 'left' }}>
          <p >ข้าพเจ้าได้ตรวจสอบ และรับทราบ</p>
          <p style={{ fontWeight: 700 }}>ลงนาม</p>
        </Col>
        <Col span={12} style={{ textAlign: 'left' }}>
          <p style={{ fontWeight: 700 }}>บริษัท ไทยดอทรัน จำกัด</p>
          <p style={{ fontWeight: 700 }}>ผู้ทำเรื่องเบิก</p>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <br />
          <p>…………………………………………………</p>
          <p>(…………………………………………………………………………)</p>
          <p>ลงวันที่ …………………………………………………</p>
        </Col>
        <Col span={12} style={{ textAlign: 'center' }}>
          <br />
          <p>…………………………………………………</p>
          <p>(…………………………………………………………………………)</p>
          <p>ลงวันที่ …………………………………………………</p>
        </Col>
        <Col span={12} offset={12} style={{ textAlign: 'center', marginTop: '40px' }}>
          <p style={{ fontWeight: 700, textAlign: 'left' }}>ผู้อนุมัติ</p>
          <br />
          <p>…………………………………………………</p>
          <p>(…………………………………………………………………………)</p>
          <p>ลงวันที่ …………………………………………………</p>
        </Col>
      </Row>
      {bankAccount?.passbookImageUrl && <div style={{ pageBreakBefore: 'always' }}>
        <img src={bankAccount?.passbookImageUrl} alt="" style={{ width: '100%' }} />
      </div>}
      {identityDocumentUrl && <div style={{ pageBreakBefore: 'always' }}>
        <img src={identityDocumentUrl} alt="" style={{ width: '100%' }} />
      </div>}
    </div>
  </div>
}

export default BrokerTransferRequestPrint
